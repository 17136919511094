import {ChangeEvent, FC, useState} from 'react';
import {ChatInputWrapper} from './ChatInput.styles';

interface ChatInputProps {
  id: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
  error: string | undefined;
}

const ChatInput: FC<ChatInputProps> = ({id, onChange, value, placeholder, error}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <ChatInputWrapper
      hover={isHover}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      error={error && error.length !== 0 ? true : false}>
      <input id={id} onChange={onChange} value={value} placeholder={placeholder} />
      {/* {error && <ErrorText>{error}</ErrorText>} */}
    </ChatInputWrapper>
  );
};

export default ChatInput;
