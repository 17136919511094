import ChatDialog from '@components/molecules/ChatDialog';
import ChatForm from '@components/molecules/ChatForm';
import ChatSuccess from '@components/molecules/ChatSuccess';
import {RootState} from '@store';
import {
  fetchCheckIsWork,
  fetchGetAuth,
  fetchGetChatMessages,
  setChatClose,
  setQuestionSuccess,
} from '@store/slices/chat';
import Image from 'next/image';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  ChatWindowWrapper,
  ChatHeader,
  ChatHeaderTitle,
  CloseBtnWrapper,
  ChatDialogContent,
  ChatFormContent,
} from './ChatWindow.styles';

const ChatWindow = () => {
  const chatState = useSelector((state: RootState) => state.chat.chatIsOpen);
  const chatAuthSuccess = useSelector((state: RootState) => state.chat.authSuccess);
  const workDay = useSelector((state: RootState) => state.chat.isWork);
  const chatToken = useSelector((state: RootState) => state.chat.chatToken);
  const chatIsOpen = useSelector((state: RootState) => state.chat.chatIsOpen);
  const questionSuccess = useSelector((state: RootState) => state.chat.questionSuccess);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('chatToken')) {
      dispatch(fetchGetAuth(localStorage.getItem('chatToken')!));
    }
    dispatch(fetchCheckIsWork());
    document.body.addEventListener('click', () => dispatch(setChatClose()));
    return () =>
      document.body.removeEventListener('click', () => dispatch(setChatClose()));
  }, []);

  useEffect(() => {
    if (!chatIsOpen) {
      dispatch(setQuestionSuccess(false));
    }
  }, [chatIsOpen]);

  useEffect(() => {
    if (chatAuthSuccess && workDay) {
      dispatch(fetchGetChatMessages({chatToken: chatToken!, idMessageLast: 0}));
    }
  }, [chatAuthSuccess, workDay]);

  return (
    <ChatWindowWrapper chatState={chatState} onClick={e => e.stopPropagation()}>
      {!questionSuccess && (
        <ChatHeader>
          <ChatHeaderTitle>Онлайн консультант</ChatHeaderTitle>
          <CloseBtnWrapper onClick={() => dispatch(setChatClose())}>
            <Image
              src={'/assets/icons/closeBtn.svg'}
              alt="Закрытие чата"
              width={9}
              height={9}
            />
          </CloseBtnWrapper>
        </ChatHeader>
      )}

      {chatAuthSuccess && workDay ? (
        <ChatDialogContent>
          <ChatDialog />
        </ChatDialogContent>
      ) : (
        <>
          {questionSuccess ? (
            <ChatSuccess />
          ) : (
            <ChatFormContent>
              <ChatForm />
            </ChatFormContent>
          )}
        </>
      )}
    </ChatWindowWrapper>
  );
};

export default ChatWindow;
