import {IChatConsultant, IChatMessage} from '@api/types/Chat';
import ChatInput from '@components/atoms/ChatInput';
import ChatMessage from '@components/atoms/ChatMessage';
import {RootState} from '@store';
import {fetchGetChatMessages, fetchSetChatMessage} from '@store/slices/chat';
import {Formik} from 'formik';
import Image from 'next/image';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {
  ChatDialogWrapper,
  ChatDialogIsBusy,
  ChatTabs,
  ChatTab,
  ChatMessages,
  ChatDialogFormWrapper,
  ChatDialogForm,
  SendButton,
} from './ChatDialog.styles';

const chatDialogFormValidationSchema = Yup.object().shape({
  message: Yup.string().min(3, 'Опишите ваш вопрос детальнее'),
});

const chatTabs = [
  {title: 'Текущий диалог', key: 'current'},
  {title: 'История диалога', key: 'history'},
];

const ChatDialog = () => {
  const [activeTab, setActiveTab] = useState('current');
  const isBusy = useSelector((state: RootState) => state.chat.isBusy);
  const busyText = useSelector((state: RootState) => state.chat.busyText);
  const chatToken = useSelector((state: RootState) => state.chat.chatToken);
  const messages: IChatMessage[] = useSelector((state: RootState) => state.chat.messages);
  const idLastMessage = useSelector((state: RootState) => state.chat.lastMessageID);
  const consultant: IChatConsultant = useSelector(
    (state: RootState) => state.chat.consultant,
  );

  const [messagesState, setMessagesState] = useState(messages);

  const dispatch = useDispatch();
  const chatMessagesRef: {current: HTMLDivElement | null} = useRef(null);

  const onSubmitHandler = (values: {message: string}) => {
    dispatch(
      fetchSetChatMessage({
        chatToken: chatToken!,
        message: values.message,
        idMessageLast: +idLastMessage,
      }),
    );
  };

  useEffect(() => {
    if (chatToken && idLastMessage !== '0') {
      dispatch(
        fetchGetChatMessages({
          chatToken: chatToken,
          idMessageLast: +idLastMessage,
        }),
      );
    }
  }, [idLastMessage]);

  useEffect(() => {
    if (chatToken && activeTab === 'current') {
      dispatch(
        fetchGetChatMessages({
          chatToken: chatToken,
          idMessageLast: +idLastMessage,
        }),
      );
      const interval = setInterval(
        () =>
          dispatch(
            fetchGetChatMessages({
              chatToken: chatToken,
              idMessageLast: +idLastMessage,
            }),
          ),
        5000,
      );
      return () => clearInterval(interval);
    }
    if (chatToken && activeTab === 'history') {
      dispatch(
        fetchGetChatMessages({
          chatToken: chatToken,
          idMessageLast: 0,
        }),
      );
      const interval = setInterval(
        () =>
          dispatch(
            fetchGetChatMessages({
              chatToken: chatToken,
              idMessageLast: 0,
            }),
          ),
        5000,
      );
      return () => clearInterval(interval);
    }
  }, [chatToken, activeTab, idLastMessage]);

  useEffect(() => {
    if (chatMessagesRef.current && messagesState.toString() !== messages.toString()) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      setMessagesState(messages);
    }
  }, [messages]);

  return (
    <ChatDialogWrapper>
      {isBusy && <ChatDialogIsBusy>{busyText}</ChatDialogIsBusy>}
      <ChatTabs>
        {chatTabs.map(tab => (
          <ChatTab
            key={tab.key}
            active={tab.key === activeTab}
            onClick={() => setActiveTab(tab.key)}>
            {tab.title}
          </ChatTab>
        ))}
      </ChatTabs>
      {activeTab === 'current' ? (
        <>
          <ChatMessages ref={chatMessagesRef}>
            {messages.length !== 0 &&
              [...messages]
                .reverse()
                .map(message => (
                  <ChatMessage
                    name={
                      message.isanswer === '1' ? consultant.name ?? 'Консультант' : 'Вы'
                    }
                    time={message.datetime}
                    message={message.text}
                    isAnswer={message.isanswer}
                    key={message.id}
                  />
                ))}
          </ChatMessages>
          <ChatDialogFormWrapper>
            <Formik
              initialValues={{message: ''}}
              onSubmit={(values, {resetForm, validateForm}) => {
                validateForm();
                onSubmitHandler(values);
                resetForm();
              }}
              validationSchema={chatDialogFormValidationSchema}
              validateOnBlur>
              {({values, handleChange, handleSubmit, errors}) => (
                <ChatDialogForm onSubmit={handleSubmit}>
                  <ChatInput
                    id="message"
                    onChange={handleChange}
                    value={values.message}
                    placeholder="Введите сообщение"
                    error={errors.message}
                  />
                  <SendButton type="submit">
                    <Image
                      src={'/assets/icons/chatSendMessageIcon.svg'}
                      width={19}
                      height={19}
                      alt="Иконка отправки сообщения"
                    />
                  </SendButton>
                </ChatDialogForm>
              )}
            </Formik>
          </ChatDialogFormWrapper>
        </>
      ) : (
        <ChatMessages ref={chatMessagesRef}>
          {messages.length !== 0 &&
            [...messages]
              .reverse()
              .map(message => (
                <ChatMessage
                  name={
                    message.isanswer === '1' ? consultant.name ?? 'Консультант' : 'Вы'
                  }
                  time={message.datetime}
                  message={message.text}
                  isAnswer={message.isanswer}
                  key={message.id}
                />
              ))}
        </ChatMessages>
      )}
    </ChatDialogWrapper>
  );
};

export default ChatDialog;
