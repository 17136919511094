import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ChatMessageWrapper = styled.div<{isAnswer: '1' | '0'}>`
  align-self: ${({isAnswer}) => (isAnswer === '1' ? 'flex-start' : 'flex-end')};
`;

export const ChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ChatMessageName = styled.div<{isAnswer: '1' | '0'}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${({isAnswer}) =>
    isAnswer === '1' ? `${theme.colors.brandGreenV2}` : `${theme.colors.gray}`};
`;

export const ChatMessageTime = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.lightGrayV2};
`;

export const ChatMessageText = styled.div<{isAnswer: '1' | '0'}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${({isAnswer}) =>
    isAnswer === '1' ? `${theme.colors.darkText}` : `${theme.colors.white}`};
  background-color: ${({isAnswer}) =>
    isAnswer === '1' ? `${theme.colors.lightBG}` : `${theme.colors.brandGreenV2}`};
  padding: 10px;
  max-width: 226px;
  word-wrap: break-word;
  margin-top: 6px;
  border-radius: 4px;
  width: fit-content;
`;
