import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ChatWindowWrapper = styled.div<{chatState: boolean}>`
  height: calc(466px + 50px);
  width: 348px;
  border-radius: 4px;
  opacity: ${({chatState}) => (chatState ? '1' : '0')};
  z-index: ${({chatState}) => (chatState ? '99999' : '-10')};
  position: fixed;
  top: calc(90% - 466px);
  right: 2.5%;
  background-color: white;
  transition: all 0.25s;
  @media (max-width: 370px) {
    right: 2%;
    left: 2%;
    width: 96%;
  }
`;

export const ChatHeader = styled.div`
  padding: 14px 24px;
  background: ${theme.colors.brandGreenV2};
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
`;

export const ChatHeaderTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
`;

export const CloseBtnWrapper = styled.div`
  ${theme.filters.white};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ChatFormContent = styled.div`
  box-sizing: border-box;
  border: 1px solid ${theme.colors.lightGrayV2};
  border-radius: 0px 0px 4px 4px;
  min-height: 466px;
  height: fit-content;
  max-height: 466px;
  padding: 24px;
  background-color: ${theme.colors.white};
  overflow: auto;
  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background: #ededed;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    width: 4px;
    background: #b1b1b1;
    border-radius: 4px;
    &:hover {
      background-color: ${theme.colors.brandGreenV2};
    }
  }
`;

export const ChatDialogContent = styled.div`
  box-sizing: border-box;
  border: 1px solid ${theme.colors.lightGrayV2};
  border-radius: 0px 0px 4px 4px;
  min-height: 466px;
  height: fit-content;
  max-height: 466px;
  padding: 16px;
  background-color: ${theme.colors.white};
  overflow: auto;
`;
