import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ChatDialogWrapper = styled.div`
  height: calc(466px - 17px * 2);
  display: flex;
  flex-direction: column;
`;

export const ChatDialogIsBusy = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkText};
  border: 1px solid ${theme.colors.opalRed};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const ChatMessages = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  height: calc(100% - 1px);
  margin-top: auto;
  gap: 12px;
  padding-right: 10px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    height: 6px;
    width: 5px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    width: 3px;
    background: #dbdbdb;
    border-radius: 5px;
    &:hover {
      background-color: ${theme.colors.brandGreenV2};
    }
  }
`;

export const ChatTabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
`;

export const ChatTab = styled.div<{active: boolean}>`
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1px;
  color: ${({active}) =>
    active ? `${theme.colors.white}` : `${theme.colors.brandGreenV2}`};
  background-color: ${({active}) =>
    active ? `${theme.colors.brandGreenV2}` : `${theme.colors.white}`};
  padding: 5px 6.5px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 10px;
  border: ${({active}) =>
    active ? `1px solid transparent` : `1px solid ${theme.colors.brandGreenV2}`};
  cursor: pointer;
  opacity: all 0.25s;
`;

export const ChatDialogFormWrapper = styled.div`
  margin-top: auto;
  padding-top: 10px;
`;

export const ChatDialogForm = styled.form`
  display: flex;
  height: 40px;
  gap: 12px;
`;

export const SendButton = styled.button`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.brandGreenV2};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s;
  img {
    transition: all 0.25s;
  }
  &:hover {
    background-color: ${theme.colors.lightGreen};
    transition: all 0.25s;
    img {
      ${theme.filters.brandGreenV2};
      transition: all 0.25s;
    }
  }
`;
