import {theme} from '@styles/theme';
import styled, {DefaultTheme} from 'styled-components';

export const ChatInputWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  border: ${({
    hover,
    theme,
    error,
  }: {
    hover: boolean;
    theme: DefaultTheme;
    error: boolean;
  }) => {
    switch (true) {
      case hover:
        return `1px solid ${theme.colors.brandGreenV2}`;
      case error:
        return `1px solid ${theme.colors.opalRed}`;
      default:
        return `1px solid ${theme.colors.lightGrayV2}`;
    }
  }};
  background-color: transparent;
  width: 100%;
  position: relative;
  transition: all 0.5s;
  & input {
    border: none;
    outline: none;
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: transparent;
    width: 100%;
    letter-spacing: 0.5px;
    color: ${theme.colors.gray};
    ::placeholder {
      font-weight: 400;
      line-height: 16px;
      font-size: 12px;
      color: ${theme.colors.lightGrayV2};
      letter-spacing: 0.1px;
    }
  }
`;

export const ErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkRed};
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 16px;
  width: fit-content;
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  z-index: 1;
`;
