import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ChatSuccessWrapper = styled.div`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGrayV2};
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SomeSuccessBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

export const SomeSuccessTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${theme.colors.brandGreenV2};
  margin-bottom: 20px;
`;

export const SomeSuccessText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${theme.colors.gray};
  text-align: center;
`;

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 19px;
  cursor: pointer;
`;
