import {setChatClose} from '@store/slices/chat';
import Image from 'next/image';
import {useDispatch} from 'react-redux';
import {
  ChatSuccessWrapper,
  CloseBtnWrapper,
  SomeSuccessBlock,
  SomeSuccessTitle,
  SomeSuccessText,
} from './ChatSuccess.styles';

const ChatSuccess = () => {
  const dispatch = useDispatch();

  return (
    <ChatSuccessWrapper>
      <CloseBtnWrapper onClick={() => dispatch(setChatClose())}>
        <Image
          src={'/assets/icons/closeBtn.svg'}
          alt="Кнопка закрытия чата"
          width={9}
          height={9}
        />
      </CloseBtnWrapper>
      <Image
        src={'/assets/icons/chatSuccessIcon.svg'}
        width={64}
        height={64}
        alt="Успешная отправка вопроса"
      />
      <SomeSuccessBlock>
        <SomeSuccessTitle>Ваш запрос отправлен</SomeSuccessTitle>
        <SomeSuccessText>Менеджер свяжется с вами в ближайшее время</SomeSuccessText>
      </SomeSuccessBlock>
    </ChatSuccessWrapper>
  );
};

export default ChatSuccess;
