import {FC} from 'react';
import {
  ChatMessageHeader,
  ChatMessageName,
  ChatMessageText,
  ChatMessageTime,
  ChatMessageWrapper,
} from './ChatMessage.styles';

interface ChatMessageProps {
  name: string;
  time: string;
  message: string;
  isAnswer: '1' | '0';
}

const ChatMessage: FC<ChatMessageProps> = ({name, time, message, isAnswer}) => {
  return (
    <ChatMessageWrapper isAnswer={isAnswer}>
      <ChatMessageHeader>
        <ChatMessageName isAnswer={isAnswer}>{name}</ChatMessageName>
        <ChatMessageTime>{time.split(' ')[1]}</ChatMessageTime>
      </ChatMessageHeader>
      <ChatMessageText isAnswer={isAnswer}>{message}</ChatMessageText>
    </ChatMessageWrapper>
  );
};

export default ChatMessage;
