import {IChatForm} from '@api/types/Chat';
import Button from '@components/atoms/Button';
import Input from '@components/atoms/Input';
import PhoneInput from '@components/atoms/PhoneInput';
import Textarea from '@components/atoms/Textarea';
import Captcha from '@components/molecules/Captcha';
import {ENABLE_RAL_CAPTCHA} from '@config/captcha';
import {RootState} from '@store';
import {fetchSetAuth} from '@store/slices/chat';
import {theme} from '@styles/theme';
import {russianPhoneRegExp} from '@utils/formatters';
import {Formik} from 'formik';
import {useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {ChatFormWrapper, Form, NoWorkDayText} from './ChatForm.styles';
import ym from 'react-yandex-metrika';

const chatFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Поле обязательно'),
  phone: Yup.string()
    .matches(russianPhoneRegExp, 'Проверьте правильность ввода номера')
    .required('Поле обязательно'),
  question: Yup.string().required('Поле обязательно'),
});

const ChatForm = () => {
  const [loading, setLoading] = useState(false);

  const {executeRecaptcha} = useGoogleReCaptcha();

  const dispatch = useDispatch();

  const workDay = useSelector((state: RootState) => state.chat.isWork);

  const onSubmitHandler = async (values: IChatForm) => {
    dispatch(fetchSetAuth(values));
    setLoading(false);
  };

  return (
    <ChatFormWrapper>
      {!workDay && (
        <NoWorkDayText>
          Добрый день! Вы обратились вне рабочего времени. Оставьте Ваше обращение, и мы
          обязательно Вам ответим. Спасибо!
        </NoWorkDayText>
      )}
      <Formik
        initialValues={{
          name: '',
          phone: '',
          question: '',
          'g-recaptcha-response': '',
          captcha: '',
        }}
        onSubmit={async (values, {resetForm}) => {
          setLoading(true);
          let token = '';
          if (executeRecaptcha) token = await executeRecaptcha();
          onSubmitHandler({...values, ['g-recaptcha-response']: token});
          ym('reachGoal', 'consultation_send');
          resetForm();
        }}
        validationSchema={!workDay ? chatFormValidationSchema : null}
        validateOnChange>
        {({handleSubmit, handleChange, setFieldValue, values, errors, touched}) => (
          <Form onSubmit={handleSubmit} loading={loading}>
            <Input
              label="Как к вам обращаться?"
              id="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Имя"
              placeholderColor={theme.colors.lightGrayV2}
              error={touched.name && errors.name ? errors.name : undefined}
            />
            <PhoneInput
              label="Телефон"
              value={values.phone}
              onChange={tel => setFieldValue('phone', tel)}
              defaultValue={''}
              id="phone"
              placeholder="+7"
              placeholderColor={theme.colors.lightGrayV2}
              allowEmptyFormatting={false}
              textColor={
                values.phone.length === 0
                  ? `${theme.colors.lightGrayV2}`
                  : `${theme.colors.darkText}`
              }
              error={touched.phone && errors.phone ? errors.phone : undefined}
            />
            {!workDay && (
              <Textarea
                id="question"
                value={values.question}
                onChange={handleChange}
                rows={5}
                label="Ваш вопрос"
                placeholder="Введите запрос"
                placeholderColor={theme.colors.lightGrayV2}
                error={touched.question && errors.question ? errors.question : undefined}
              />
            )}
            <Captcha
              vertical
              type={ENABLE_RAL_CAPTCHA ? 'ral' : 'recaptcha'}
              onChange={handleChange}
              value={values.captcha}
              error={
                ENABLE_RAL_CAPTCHA
                  ? errors.captcha && touched.captcha
                    ? errors.captcha
                    : ''
                  : errors['g-recaptcha-response'] && touched['g-recaptcha-response']
                  ? errors['g-recaptcha-response']
                  : ''
              }
              validationError={
                ENABLE_RAL_CAPTCHA
                  ? Boolean(errors.captcha && touched.captcha)
                  : Boolean(
                      errors['g-recaptcha-response'] && touched['g-recaptcha-response'],
                    )
              }
            />
            <Button
              type="submit"
              title="Отправить"
              style={{width: '100%', padding: '15px 0px'}}
              variant="filled"
              disabled={loading}
            />
          </Form>
        )}
      </Formik>
    </ChatFormWrapper>
  );
};

export default ChatForm;
