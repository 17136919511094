import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ChatFormWrapper = styled.div`
  transition: opacity 0.5s;
`;

export const Form = styled.form.withConfig<{loading?: boolean}>({
  shouldForwardProp: prop => prop !== 'loading',
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: ${({loading}) => (loading ? '0.5' : '1')};
`;

export const NoWorkDayText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkText};
  margin-bottom: 16px;
`;
